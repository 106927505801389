import Helmet from "react-helmet";
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getSrc, IGatsbyImageData } from "gatsby-plugin-image";

type TProps = {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string | IGatsbyImageData;
};

export const OxPageHelmet: React.FC<TProps> = ({
  description,
  image,
  keywords,
  title,
}: TProps) => {
  const { site } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          siteName
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          defaultKeywords: keywords
          siteUrl
          defaultImage: image
          twitterUsername
        }
      }
    }
  `);

  if (typeof image === "object") {
    image = getSrc(image);
  }

  const {
    siteName,
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultKeywords,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords: keywords || defaultKeywords,
    image: `${image || defaultImage}`,
  };

  return (
    <Helmet
      titleTemplate={titleTemplate}
      title={seo.title}
      htmlAttributes={{ lang: "en" }}
    >
      <meta httpEquiv="content-language" content="en-gb" />
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />

      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image" content={seo.image} />

      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />

      <meta
        name="facebook-domain-verification"
        content="25s4tegcn7p1djnuql7afj59imk9n4"
      />
    </Helmet>
  );
};
