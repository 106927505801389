import styled, { css } from "styled-components";

import { fluidSizing } from "src/utils";

export const OxComponentContainer = styled.div<{
  marginTop?: number[];
  marginBottom?: number[];
}>(
  ({ marginTop, marginBottom }) => css`
    width: 100%;
    ${fluidSizing([
      { prop: "margin-top", values: marginTop || [] },
      { prop: "margin-bottom", values: marginBottom || [] }
    ])}
  `
);
